/* src/styles/Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.login-form {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 350px;
}

.login-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
}

.login-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.login-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: #d32f2f;
    margin-bottom: 1rem;
    text-align: center;
}

.login-links {
    text-align: center;
    margin-top: 1rem;
}

.login-links a {
    color: #007bff;
    text-decoration: none;
    margin: 0 0.5rem;
    transition: color 0.3s;
}

.login-links a:hover {
    color: #0056b3;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}