.billing-page {
    display: flex;
}

.billing-content {
    margin-left: 250px;
    /* same as the width of the side panel */
    padding: 20px;
}

.modal h2 {
    margin-top: 0;
}

.modal form {
    display: flex;
    flex-direction: column;
}

.modal input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
}

.modal button {
    padding: 10px;
    font-size: 16px;
    background: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}