.home-page {
    display: flex;
}

.home-content {
    margin-left: 250px;
    /* same as the width of the side panel */
    padding: 20px;
    width: calc(100% - 250px);
    /* Fill the remaining space to the right of the side panel */
    height: 100vh;
    /* Fill the full height of the viewport */
}

.card {
    margin-top: 20px;
    margin-bottom: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Adjust the spinner styles */
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #007bff;
    /* Use the button's color for consistency */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

.instructional-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px 0;
}

.instructional-video {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 20px 0;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 400px;
}

.messages {
    flex-grow: 1;
    overflow-y: auto;
}

.my-messages {
    text-align: right;
}

.their-messages {
    text-align: left;
}

.message {
    padding: 5px 10px;
    margin: 5px;
    border-radius: 10px;
    background-color: #f1f1f1;
}

.chat-input {
    display: flex;
    margin-top: 10px;
}

.chat-input .form-control {
    margin-right: 5px;
}

.original-message {
    background-color: white; /* A light green background for the original message */
    text-align: right;
}

.response-message {
    background-color: #f2f2f2; /* A lighter gray background for responses, but not as light as #f9f9f9 */
    text-align: left;
}