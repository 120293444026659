.green-gradient-bg {
    background-color: #19c37d;
    background-image: url(https://cdn.openai.com/API/images/icon_bg_green.svg);
    background-size: cover;
}

.icon-item-icon {
    border-radius: 8px;
    color: #fff;
    flex: 0 0 auto;
    font-size: 24px;
    cursor: pointer;
    line-height: 0px;
    padding: 15px;
}