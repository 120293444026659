.card-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.icon-text-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon-text-container div {
    display: flex;
    flex-direction: column;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Adjust the spinner styles */
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #007bff; /* Use the button's color for consistency */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}